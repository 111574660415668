import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { CargoListPageSearchParams } from '../../constants'

export function CargoTableHead() {
  const [searchParams, setSearchParams] = useSearchParams()
  const isDescendingDate =
    searchParams.get(CargoListPageSearchParams.orderByDate) === 'desc'

  const headers = [
    'P&C',
    'Date',
    'Cargo',
    'Laycan',
    'Load/Delivery',
    'Discharge',
    'Broker',
    'Charterer',
    'Qty/Period',
    'Rate/Hire',
    'Vessel',
    'PIC',
    'Comments',
    'Status',
  ]

  return (
    <TableBody>
      <TableRow>
        {headers.map((header, index) => (
          <TableCell key={index} sx={{ px: 2, py: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="label">{header}</Typography>

              {header === 'Date' && (
                <TableSortLabel
                  active
                  direction={isDescendingDate ? 'desc' : 'asc'}
                  onClick={(e) =>
                    setSearchParams({
                      [CargoListPageSearchParams.orderByDate]: isDescendingDate
                        ? 'asc'
                        : 'desc',
                    })
                  }
                />
              )}
            </Box>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableBody>
  )
}
