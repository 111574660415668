import { Autocomplete, TextField, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'

interface SingleItemAutocomplete {
  input?: string | null
  setInputValue?: (value: string) => void
  label: string
  onChangeHandler: (e: any, newValue: string | null) => void
  options: string[]
  hasError?: boolean
  [key: string]: any
}

export function SingleItemAutocomplete({
  input = '',
  setInputValue,
  label,
  onChangeHandler,
  options,
  hasError,
  disabled,
  ...rest
}: SingleItemAutocomplete) {
  return (
    <Autocomplete
      value={input}
      freeSolo
      size="small"
      autoSelect
      disablePortal
      forcePopupIcon={false}
      options={options}
      onChange={onChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          error={hasError}
        />
      )}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== input) {
          setInputValue?.(newInputValue)
        }
      }}
      disabled={disabled}
      {...rest}
    />
  )
}
