import { useEffect } from 'react'
import { FormInput } from '../componenets/cargo-modal/add-cargo-modal'

export function useAutofocus(formInput: FormInput) {
  useEffect(() => {
    setTimeout(() => {
      const poolInput = findInputByLabel('Pool(s) *')
      const laycanInput = findInputByLabel('Laycan *')

      if (poolInput && formInput.pool?.length === 0) {
        poolInput.focus()
        return
      }
      if (laycanInput && !formInput.laycanFrom) {
        laycanInput.focus()
        return
      }
    }, 100) // Timeout to ensure the DOM is fully loaded
  }, [])
}

function findInputByLabel(labelText: string): HTMLInputElement | null {
  const label = Array.from(document.querySelectorAll('label')).find(
    (lbl) =>
      lbl.textContent?.trim()?.toLowerCase() === labelText?.toLowerCase(),
  )

  if (label) {
    const inputId = label.getAttribute('for')
    if (inputId) {
      return document.getElementById(inputId) as HTMLInputElement | null
    }

    const parent = label.closest('.MuiFormControl-root')
    if (parent) {
      return parent.querySelector('input') as HTMLInputElement | null
    }
  }

  return null
}
