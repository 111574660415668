import { Select } from '@maersktankersdigital/web-components'
import { Box, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useMemo } from 'react'
import { COLORS } from '~theme/colors'
import { cargoStatusesWithHiddenFormFields } from '../constants'
import {
  quantityUnitOptions,
  rateOptions,
} from '../content/select-n-autocomplete-options'
import {
  Area,
  Broker,
  ChartererAutocomplete,
  Comment,
  CommercialOperator,
  CpDate,
  DateInputField,
  Port,
  TimeInputField,
  VesselAutocomplete,
} from './fields'
import { FieldsGroupProps } from './shared-fields'

export function SpotFields(props: FieldsGroupProps) {
  const { updateFormInput, formInput, missingFields, disabledFields } = props

  const showExtraFields = useMemo(
    () =>
      !formInput.status ||
      !cargoStatusesWithHiddenFormFields.includes(formInput.status),
    [formInput.status],
  )

  return (
    <>
      <Grid size={{ xs: 12, md: 6, xxxl: 3 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <TextField
              size="small"
              label="Quantity *"
              value={formInput.quantity}
              onChange={(e) =>
                updateFormInput({
                  quantity:
                    e.target.value === '' ? undefined : Number(e.target.value),
                })
              }
              type="number"
              error={missingFields?.includes('quantity')}
              disabled={disabledFields?.includes('quantity')}
            />
          </Box>
          <Select
            name="unit"
            options={quantityUnitOptions.map((unit) => ({
              label: unit,
              value: unit,
            }))}
            slotProps={{ popper: { style: { zIndex: 9999 } } }}
            onChange={(e, value) => updateFormInput({ quantityType: value })}
            value={formInput.quantityType}
            // @ts-ignore
            error={missingFields?.includes('quantityType')}
            disabled={disabledFields?.includes('quantityType')}
          />
        </Box>
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 2 }}>
        <Broker {...props} />
      </Grid>
      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <ChartererAutocomplete {...props} />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Port
          {...props}
          label="Load Port"
          variant="loadPort"
          dependentInput="loadWorldArea"
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Area {...props} label="Load World Area" variant="loadWorldArea" />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Port
          {...props}
          label="Discharge Port"
          variant="dischargePort"
          dependentInput="dischargeWorldArea"
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Area
          {...props}
          label="Discharge World Area"
          variant="dischargeWorldArea"
        />
      </Grid>

      {showExtraFields && (
        <>
          <Divider sx={{ width: '100%' }} />
          <Grid size={{ xs: 12, lg: 6, xxxl: 6 }}>
            <VesselAutocomplete {...props} />
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 6 }}>
            <CommercialOperator {...props} />
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <DateInputField
                  label="Subs due time"
                  sx={{ flexGrow: 1 }}
                  onChangeHandler={(value) =>
                    updateFormInput({ subsDueTime: value })
                  }
                  dateTime={formInput.subsDueTime}
                  hasError={missingFields?.includes('subsDueTime')}
                  disabled={disabledFields?.includes('subsDueTime')}
                />
                <TimeInputField
                  onChangeHandler={(value) =>
                    updateFormInput({ subsDueTime: value })
                  }
                  dateTime={formInput.subsDueTime}
                  hasError={missingFields?.includes('subsDueTime')}
                  disabled={disabledFields?.includes('subsDueTime')}
                />
              </Box>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
              >
                <TextField
                  size="small"
                  label="Rate"
                  value={formInput.rateHire}
                  onChange={(e) =>
                    updateFormInput({ rateHire: e.target.value })
                  }
                  error={missingFields?.includes('rateHire')}
                  disabled={disabledFields?.includes('rateHire')}
                />
              </Box>
              <Select
                name="rate"
                options={rateOptions.map((name) => ({
                  label: name,
                  value: name,
                }))}
                slotProps={{ popper: { style: { zIndex: 9999 } } }}
                value={formInput.rateHireType}
                onChange={(e, value) =>
                  updateFormInput({ rateHireType: value })
                }
                // @ts-ignore
                error={missingFields?.includes('rateHireType')}
                disabled={disabledFields?.includes('rateHireType')}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, lg: 6, xxxl: 2 }}>
            <CpDate {...props} />
          </Grid>

          <Divider sx={{ width: '100%' }} />
        </>
      )}

      <Grid size={12}>
        <Comment {...props} />
      </Grid>
    </>
  )
}
