import { Button, TextButton } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { Modal } from '~components/molecules/modal/modal'
import { PoolName } from '~constants/pools'
import {
  PostCargoPayload,
  usePostCargo,
} from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { tradingWorldwide } from '../../constants'
import {
  CargoStatus,
  periodOptions,
} from '../../content/select-n-autocomplete-options'
import { apiStatusMessages, missingRequired } from '../../content/toast-content'
import { useAutofocus } from '../../hooks/use-autofocus'
import { CargoForm } from '../cargo-form'
import { getMissingRequiredFields } from '../utils/required-fields-check'

export type Type = 'SPOT' | 'T/C'

export type FormInput = {
  broker?: string
  cargoGrade?: string[]
  charterer?: string
  commercialOperator?: string
  comments?: string
  cpDate?: Date
  dateQuoted?: Date
  dischargePort?: string[]
  dischargeWorldArea?: string[]
  hireDetails?: string
  laycanFrom?: Date
  laycanTo?: Date
  loadPort?: string[]
  loadWorldArea?: string[]
  periodDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  pic?: string
  pool?: string[]
  privateAndConfidential?: boolean
  quantity?: number
  quantityType?: string
  rateHire?: string
  rateHireType?: string
  reminderDate?: Date
  status?: CargoStatus
  subsDueTime?: Date
  tradingType?: Type
  tradingWorldArea?: string[]
  vessel?: string
}

function useDefaultFormInput() {
  const { data } = useGetMe()
  const [searchParams] = useSearchParams()
  const poolsParam = searchParams.getAll('pools') as string[]
  const validPools = Object.values(PoolName)
  const sanitizedPools = validPools.filter((validPool) =>
    poolsParam.some(
      (poolParam) => validPool.toLowerCase() === poolParam.toLowerCase(),
    ),
  )

  const defaultFormInput: FormInput = {
    tradingType: 'SPOT',
    privateAndConfidential: true,
    dateQuoted: new Date(),
    status: CargoStatus.Open,
    quantityType: 'MT',
    periodTcOutType: periodOptions[0],
    rateHireType: 'WS',
    pool: sanitizedPools,
    tradingWorldArea: [tradingWorldwide],
    ...(data?.name ? { pic: data.name } : {}),
  }

  return defaultFormInput
}

export function AddCargoModal({ onClose }: { onClose: () => void }) {
  const { setStatus } = useAnimatedAlert()
  const { mutate: postCargo, status: postStatus } = usePostCargo()
  const defaultFormInput = useDefaultFormInput()
  const [formInput, setFormInput] = useState<FormInput>(defaultFormInput)
  const [missingFields, setMissingFields] = useState<(keyof FormInput)[]>()
  useAutofocus(formInput)

  useEffect(() => {
    const handleStatus = (status: string, action: 'post') => {
      if (status === 'success' || status === 'error') {
        setStatus(apiStatusMessages[status][action], undefined, status)

        if (status === 'success') onClose()
      }
    }

    handleStatus(postStatus, 'post')
  }, [postStatus])

  function updateFormInput(input: FormInput) {
    setFormInput({ ...formInput, ...input })
  }

  function onAddHandler() {
    if (!formInput) {
      onClose()
      return
    }

    const missingRequiredFields = getMissingRequiredFields(formInput)
    if (missingRequiredFields.length > 0) {
      setStatus(missingRequired, undefined, 'error')
      setMissingFields(missingRequiredFields)
      return
    }

    postCargo(formInput as PostCargoPayload)
  }

  return (
    <>
      <Modal variant="wide" open title="Add new cargo" onClose={onClose}>
        <>
          <CargoForm
            formInput={formInput}
            updateFormInput={updateFormInput}
            missingFields={missingFields}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onAddHandler}>Add</Button>
            <TextButton onClick={onClose}>Cancel</TextButton>
          </Box>
        </>
      </Modal>
    </>
  )
}
