import { Typography } from '@mui/material'
import { Tooltip } from '~components/tooltip'

export function ValueWithTooltip({
  value,
  tooltipText,
}: {
  value?: string | number | React.ReactNode
  tooltipText?: string | number | React.ReactNode
}) {
  if (!value) return <></>
  const component =
    typeof value === 'string' || typeof value === 'number' ? 'p' : 'article'

  if (!tooltipText)
    return (
      <Typography
        variant="caption"
        sx={{ whiteSpace: 'nowrap' }}
        component={component}
      >
        {value}
      </Typography>
    )

  return (
    <Tooltip title={tooltipText} placement="bottom-start">
      <Typography
        variant="caption"
        sx={{ whiteSpace: 'nowrap' }}
        component={component}
      >
        {value}
      </Typography>
    </Tooltip>
  )
}
