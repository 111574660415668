import { GoToIcon } from '@maersktankersdigital/web-components'
import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useSecondaryFinancialNavLinks } from '~components/navigation-and-footer/navigation/left-navigation/links/financial-secondary'
import { COLORS } from '~theme/colors'
import { MenuItem } from './components/menu-item'
import { usePrimaryFinancialNavLinks } from './links/financial-primary'
import { useVesselLinks } from './links/vessel-links'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'

export const LeftNavigation = ({
  leftNavigationPage,
}: {
  leftNavigationPage: string
}) => {
  const primaryFinancialNavLinks = usePrimaryFinancialNavLinks()
  const secondaryFinancialNavLinks = useSecondaryFinancialNavLinks()
  const { topNavigationHeight } = useNavAndFooterConstants()
  const vesselLinks = useVesselLinks()

  return (
    <Box
      sx={{
        overflow: 'auto',
        marginTop: topNavigationHeight,
      }}
    >
      <List>
        {leftNavigationPage === 'financials' &&
          primaryFinancialNavLinks?.map((item) => {
            return <MenuItem key={item.label} {...item} />
          })}
        {leftNavigationPage === 'financials' &&
          secondaryFinancialNavLinks.length > 0 && (
            <Divider sx={{ my: 4, borderColor: COLORS.secondary.darkBlue }} />
          )}
        {leftNavigationPage === 'financials' &&
          secondaryFinancialNavLinks.map((item) => {
            return (
              <ListItem
                key={item.label}
                sx={{
                  padding: '20px 0 20px 32px',
                  '& path': {
                    fill: COLORS.primary.white,
                  },
                  '& a, span': {
                    color: COLORS.primary.white,
                    textDecoration: 'none',
                    transition: 'color .3s ease',
                  },
                  '&:hover': {
                    '& path': {
                      fill: COLORS.primary.maerskBlue,
                    },
                    '& a, span': {
                      color: COLORS.primary.maerskBlue,
                    },
                  },
                }}
              >
                <a href={item.link} rel="noopener" target="blank">
                  <Typography variant="menu" sx={{ mr: 1 }}>
                    {item.label}
                  </Typography>
                </a>
                <GoToIcon />
              </ListItem>
            )
          })}
        {leftNavigationPage === 'vessels' &&
          vesselLinks.map((item) => <MenuItem key={item.link} {...item} />)}
      </List>
    </Box>
  )
}
