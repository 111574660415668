import { Params } from 'react-router-dom'
import { ApiRoutes } from '~api'
import { apiGetAction } from '~api/actions'
import { GuidedTourStatus } from '~components/guided-tour/content/guided-tours-list'
import { ICargoFilters } from '~pages/pages-behind-login/chartering/cargo-list-page/componenets/cargo-filters'
import { ExposureFilterTypes } from '~pages/pages-behind-login/chartering/contexts/exposure-filter-provider'
import {
  ColumnSort,
  FilterTypes,
} from '~pages/pages-behind-login/chartering/contexts/position-list-view-provider'
import { Scope } from './roles/scope-types'

export interface IUserSettingsVessels {
  favourites: string[]
}

export interface IUserSettingsPositionListView {
  columns?: string[]
  filterSettings?: FilterTypes
  name?: string
  showHeaders?: boolean
  sortColumn?: ColumnSort
  createdAt?: string
}

export interface IUserSettingsPositionList {
  activeViewName?: string
  favoriteVessels?: string[]
  myLists?: Record<string, IUserSettingsPositionListView | null>
  exposureFilters?: ExposureFilterTypes
  v2Lists?: { name: string; filter: string; timestamp: number }[]
  v2LastViewed?: string
}

export interface IUserSettings {
  positionList?: IUserSettingsPositionList
  vessels?: IUserSettingsVessels
  guidedTours?: GuidedTour[]
  cargoList?: IUserSettingsCargoList
}

export interface IUserInfoReadResponse {
  ToSAcceptedDate?: string
  activePools?: string[]
  profitCenterPools?: string[]
  active?: boolean
  department?: string
  email: string
  favoriteVesselsNames: IFavoriteVesselsNames[]
  name?: string
  phoneNumber?: string
  roles?: Role[]
  secondaryPhoneNumber?: string
  settings?: IUserSettings
  statusCode?: string
  sub: string | null
  scope: Scope
  isInternal: boolean
  poolPartnerKeys: string[]
}

export interface GuidedTour {
  id: string
  status: GuidedTourStatus
}

export interface IUserSettingsCargoList {
  filters?: ICargoFilters
}
interface IFavoriteVesselsNames {
  _id: string
  vesselName: string
}

export interface Role {
  entity: string
  role?: string
  id?: string
}

export interface IUserInviteReadResponse {
  email: string
  name?: string
  phone_number?: string
}

export function userInviteReadAction(
  urlParams?: Params,
): Promise<IUserInviteReadResponse> {
  return apiGetAction<IUserInviteReadResponse>(
    ApiRoutes.User.readInvite,
    urlParams,
    false,
  )
}
