import { Autocomplete, TextField } from '@mui/material'

export function MultiItemAutocomplete({
  input,
  setInputValue,
  label,
  onChangeHandler,
  options,
  hasError,
  disabled,
  persistOptionsScroll,
  ...rest
}: {
  input: string[]
  setInputValue?: (value: string) => void
  label: string
  onChangeHandler: (e: any, newValues: string[]) => void
  options: any[]
  hasError?: boolean
  disabled?: boolean
  persistOptionsScroll?: boolean
  [key: string]: any
}) {
  const optionsWithoutInput = options.filter(
    (option) => !input.includes(option),
  )

  return (
    <Autocomplete
      value={input}
      slotProps={
        !persistOptionsScroll
          ? {}
          : {
              listbox: {
                role: 'list-box',
              },
            }
      }
      freeSolo
      multiple
      disablePortal
      disableClearable
      forcePopupIcon={false}
      options={optionsWithoutInput}
      onChange={onChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={disabled ? '' : 'Search...'}
          error={hasError}
        />
      )}
      onInputChange={(event, newInputValue) => {
        setInputValue?.(newInputValue)
      }}
      disabled={disabled}
      disableCloseOnSelect
      limitTags={1}
      size="small"
      {...rest}
    />
  )
}
