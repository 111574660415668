import { useState } from 'react'
import { useGetCommercialOperator } from '~hooks/queries/cargo/use-get-commercial-operator'
import { minCharsForAutocomplete, toBeNamedText } from '../../constants'
import { FieldsGroupProps } from '../shared-fields'
import { SingleItemAutocomplete } from './autocomplete/base-components/single-item-autocomplete'

export const vesselNameBlocklist = [toBeNamedText]

export function CommercialOperator({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const [inputValue, setInputValue] = useState('')

  const { data } = useGetCommercialOperator({
    commercialOperatorName: inputValue,
    vesselName: formInput.vessel,
    disabled:
      (!formInput.vessel && inputValue.length < minCharsForAutocomplete) ||
      (!!formInput.vessel && vesselNameBlocklist.includes(formInput.vessel)),
  })

  return (
    <SingleItemAutocomplete
      label="Commercial Operator"
      input={formInput.commercialOperator}
      setInputValue={setInputValue}
      onChangeHandler={(e, value) =>
        updateFormInput({ commercialOperator: value || undefined })
      }
      options={!data || inputValue.length < minCharsForAutocomplete ? [] : data}
      hasError={missingFields?.includes('commercialOperator')}
      disabled={disabledFields?.includes('commercialOperator')}
    />
  )
}
