import { Box, TextField, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'
import { FieldsGroupProps } from '../shared-fields'

export function Broker({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        label="Broker"
        size="small"
        value={formInput.broker}
        onChange={(e) => updateFormInput({ broker: e.target.value })}
        error={missingFields?.includes('broker')}
        disabled={disabledFields?.includes('broker')}
      />
    </Box>
  )
}
