import { TextField } from '@mui/material'
import { format, isValid } from 'date-fns'
import { useState } from 'react'
import { NumberFormatValues, PatternFormat } from 'react-number-format'

const minuteRegex = /^([0-5]\d)$/
const hourRegex = /^([01]\d|2[0-3])$/

export function TimeInputField({
  onChangeHandler,
  dateTime,
  hasError,
  disabled,
}: {
  onChangeHandler: (value?: Date) => void
  dateTime?: Date
  hasError?: boolean
  disabled?: boolean
}) {
  const [invalidFormat, setInvalidFormat] = useState(false)
  const formattedValue = !dateTime ? '' : format(dateTime, 'HH:mm')

  function onValueChangeHandler({
    formattedValue,
    floatValue,
  }: NumberFormatValues) {
    if (!floatValue) {
      onChangeHandler(dateTime || undefined)
      setInvalidFormat(false)
      return
    }

    const valArray = formattedValue.split(':')

    if (
      !hourRegex.test(valArray[0] || '') ||
      !minuteRegex.test(valArray[1] || '')
    ) {
      setInvalidFormat(true)
      return
    }

    const hours = parseInt(valArray[0], 10)
    const minutes = parseInt(valArray[1], 10)

    const dateInput = dateTime ? new Date(dateTime) : new Date()
    dateInput.setHours(hours)
    dateInput.setMinutes(minutes)

    if (!isValid(dateInput)) {
      setInvalidFormat(true)
      return
    }

    onChangeHandler(dateInput)
    setInvalidFormat(false)
  }

  return (
    <PatternFormat
      size="small"
      format="##:##"
      customInput={TextField}
      sx={{ width: '118px' }}
      placeholder="hh:mm"
      onValueChange={onValueChangeHandler}
      value={formattedValue}
      helperText={!invalidFormat ? '' : 'Invalid format use hh:mm'}
      error={hasError}
      disabled={disabled}
    />
  )
}
