import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface Q88ListResItem {
  IMONumber: string
  VesselName?: string
}

export function useGetQ88List({
  vesselNameAndImo,
  disabled,
}: { vesselNameAndImo?: string; disabled?: boolean } = {}) {
  const fetch = useFetch()

  let url = '/q88/list'
  if (vesselNameAndImo) {
    url += `?vesselNameAndImo=${vesselNameAndImo}`
  }
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const data = await fetch(`${apiBase()}${url}`)
      return vesselNameAndImo ? (data as string[]) : (data as Q88ListResItem[])
    },
    staleTime: tenMinutes,
    enabled: !disabled,
  })
}
