import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import { NavLink } from 'react-router-dom'
import { MenuItemI } from '../links/financial-primary'
import { COLORS } from '~theme/colors'

export function MenuItem({ link, label }: MenuItemI) {
  return (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <NavLink to={link}>
        {({ isActive }) => {
          return (
            <ListItem
              sx={{
                padding: '20px 0 20px 32px',
                '& path': {
                  fill: isActive
                    ? COLORS.primary.maerskBlue
                    : COLORS.primary.white,
                },
                '& a, span': {
                  color: isActive
                    ? COLORS.primary.maerskBlue
                    : COLORS.primary.white,
                  textDecoration: 'none',
                  transition: 'color .3s ease',
                },
                '&:hover': {
                  '& path': {
                    fill: COLORS.primary.maerskBlue,
                  },
                  '& a, span': {
                    color: COLORS.primary.maerskBlue,
                  },
                },
              }}
            >
              <Typography variant="menu" sx={{ mr: 3 }}>
                {label}
              </Typography>
            </ListItem>
          )
        }}
      </NavLink>
    </Box>
  )
}
