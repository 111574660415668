import { Alert, Loader } from '@maersktankersdigital/web-components'
import { Divider, Typography } from '@mui/material'
import { isWithinInterval, parse, parseISO, startOfDay } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { allAreaLevels } from '~pages/pages-behind-login/chartering/v2/constants'
import {
  CargoListPageSearchParams,
  dateFormat,
  defaultFilters,
  statusesNeedingPeriodFilter,
} from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { GetCargoItem, useGetCargo } from '../../hooks/use-get-cargo'
import { removeLeadingNumbers } from '../fields/area'
import { CargoTableBody } from './cargo-table-body'
import { CargoTableHead } from './cargo-table-head'
import { SingleCellTableBody } from './single-cell-table-body'

interface CargoStatusTableProps {
  statuses: CargoStatus[]
  title: string
}

export function CargoTableSection({ statuses, title }: CargoStatusTableProps) {
  const { data, isLoading, isError } = useGetFilteredCargo(statuses)

  return (
    <>
      <SingleCellTableBody>
        <Typography sx={{ pl: 2, pb: 1, pt: 5 }}>
          {`${title} ${data?.length ? `(${data?.length})` : ''}`}
        </Typography>
        <Divider />
      </SingleCellTableBody>
      {isLoading ? (
        <SingleCellTableBody>
          <Loader />
        </SingleCellTableBody>
      ) : isError ? (
        <SingleCellTableBody>
          <Alert variant="error" text="There was an error getting cargo" />
        </SingleCellTableBody>
      ) : !data || data.length === 0 ? (
        <SingleCellTableBody>
          <Alert
            variant="info"
            text="There is no cargo with this status. Try to set different filters."
          />
        </SingleCellTableBody>
      ) : (
        <>
          <CargoTableHead />
          <CargoTableBody data={data} />
        </>
      )}
    </>
  )
}

function useGetFilteredCargo(statuses: CargoStatus[]) {
  const [searchParams] = useSearchParams()
  const worldAreaParam = searchParams.getAll(
    CargoListPageSearchParams.worldArea,
  )
  const poolsParam = searchParams.getAll(CargoListPageSearchParams.pools)
  const cargoGradeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoGrade,
  )
  const periodFromParam = searchParams.get(CargoListPageSearchParams.periodFrom)
  const periodToParam = searchParams.get(CargoListPageSearchParams.periodTo)
  const cargoTypeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoType,
  )
  const picParam = searchParams.getAll(CargoListPageSearchParams.pic)
  const isDescendingDate =
    searchParams.get(CargoListPageSearchParams.orderByDate) === 'desc'

  const { data, isLoading, isError } = useGetCargo()

  const filterByStatus = (cargo: GetCargoItem) =>
    statuses
      .map((status) => status.toLowerCase())
      .includes(cargo.status.toLowerCase())

  const filterByPool = (cargo: GetCargoItem) => {
    if (!poolsParam.length) return true
    const lowerCasePoolsParam = poolsParam.map((param) => param.toLowerCase())
    if (Array.isArray(cargo.pool)) {
      return cargo.pool.some((pool) =>
        lowerCasePoolsParam.includes(pool.toLowerCase()),
      )
    }
    return lowerCasePoolsParam.includes(cargo.pool.toLowerCase())
  }

  const filterByCargoGrade = (cargo: GetCargoItem) => {
    if (!cargoGradeParam.length) return true
    return cargo.cargoGrade?.some((grade) =>
      cargoGradeParam.some((param) =>
        grade.toLowerCase().includes(param.toLowerCase()),
      ),
    )
  }

  const filterByWorldArea = (cargo: GetCargoItem) => {
    if (!worldAreaParam.length) return true

    const getWorldAreaParamValues = () => {
      return worldAreaParam
        .map((param) => {
          const area = allAreaLevels.find((level) => {
            return (
              removeLeadingNumbers(level.value.toLowerCase()) ===
                param.toLowerCase() ||
              level.label.toLowerCase() === param.toLowerCase()
            )
          })
          return area ? removeLeadingNumbers(area.value) : param
        })
        .filter(Boolean)
    }

    const getLoadWorldAreas = () => {
      return cargo.loadWorldArea?.map((area) => {
        const cargoArea = allAreaLevels.find(
          (level) =>
            removeLeadingNumbers(level.value.toLowerCase()) ===
              area.toLowerCase() ||
            level.label.toLowerCase() === area.toLowerCase(),
        )
        return cargoArea
          ? { ...cargoArea, value: removeLeadingNumbers(cargoArea.value) }
          : { label: area, value: area, level: area }
      })
    }

    const worldAreaParamValues = getWorldAreaParamValues()
    const loadWorldAreas = getLoadWorldAreas()

    return loadWorldAreas?.some((area) =>
      worldAreaParamValues.some(
        (param) =>
          area?.level?.toLowerCase().includes(param?.toLowerCase() ?? '') ||
          area?.label?.toLowerCase().includes(param?.toLowerCase() ?? ''),
      ),
    )
  }

  const filterByPeriod = (cargo: GetCargoItem) => {
    if (!statusesNeedingPeriodFilter.includes(cargo.status)) return true

    const fromDate =
      periodFromParam || defaultFilters[CargoListPageSearchParams.periodFrom]

    const toDate =
      periodToParam || defaultFilters[CargoListPageSearchParams.periodTo]

    if (!fromDate || !toDate || !cargo.dateQuoted) return true

    const start = startOfDay(parse(fromDate, dateFormat, new Date()))
    const end = startOfDay(parse(toDate, dateFormat, new Date()))
    const dateQuoted = startOfDay(parseISO(cargo.dateQuoted))
    return isWithinInterval(dateQuoted, { start, end })
  }

  const filterByTradingType = (cargo: GetCargoItem) => {
    if (!cargoTypeParam.length || !cargo.tradingType) return true
    return cargoTypeParam.some(
      (type) => type.toLowerCase() === cargo.tradingType?.toLowerCase(),
    )
  }

  const filterByPic = (cargo: GetCargoItem) => {
    if (!picParam.length || !cargo?.pic) return true
    const lowerCasePicParam = picParam.map((param) => param.toLowerCase())
    if (Array.isArray(cargo.pic)) {
      return cargo.pic.some((pic) =>
        lowerCasePicParam.includes(pic.toLowerCase()),
      )
    }
    return lowerCasePicParam.includes(cargo?.pic?.toLowerCase())
  }

  const filteredData = data
    ?.filter(filterByStatus)
    ?.filter(filterByPool)
    ?.filter(filterByCargoGrade)
    ?.filter(filterByWorldArea)
    ?.filter(filterByPeriod)
    ?.filter(filterByTradingType)
    ?.filter(filterByPic)

  if (isDescendingDate) {
    // BE returns data in ascending order
    filteredData?.reverse()
  }

  return {
    data: filteredData,
    isLoading: isLoading || (!isError && !filteredData),
    isError,
  }
}
