import { SxProps, TextField, Theme } from '@mui/material'
import { format, isValid, parse } from 'date-fns'
import { useState } from 'react'
import { NumberFormatValues, PatternFormat } from 'react-number-format'

const dayRegex = /^(0[1-9]|[12][0-9]|3[01])$/
const monthRegex = /^(0[1-9]|1[0-2])$/
const yearRegex = /^\d{2}$/
const dateFormat = 'dd-MM-yy'

export function DateInputField({
  onChangeHandler,
  dateTime,
  hasError,
  sx,
  disabled,
  label,
}: {
  onChangeHandler: (dateTime?: Date) => void
  dateTime?: Date | string
  hasError?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
  label?: string
}) {
  const [invalidFormat, setInvalidFormat] = useState(false)
  const formattedDate = !dateTime ? '' : format(new Date(dateTime), dateFormat)

  function onValueChangeHandler({
    formattedValue,
    floatValue,
  }: NumberFormatValues) {
    if (!floatValue) {
      onChangeHandler(undefined)
      setInvalidFormat(false)
      return
    }

    const valArray = formattedValue.split('-')

    if (
      !dayRegex.test(valArray[0] || '') ||
      !monthRegex.test(valArray[1] || '') ||
      !yearRegex.test(valArray[2] || '')
    ) {
      setInvalidFormat(true)
      return
    }

    const dateInput = parse(formattedValue, dateFormat, new Date())

    if (dateTime) {
      dateInput.setHours(new Date(dateTime).getHours())
      dateInput.setMinutes(new Date(dateTime).getMinutes())
    }

    if (!isValid(dateInput)) {
      setInvalidFormat(true)
      return
    }

    onChangeHandler(dateInput)
    setInvalidFormat(false)
  }

  return (
    <PatternFormat
      format="##-##-##"
      customInput={TextField}
      label={label}
      sx={{ ...sx }}
      size="small"
      placeholder="dd-mm-yy"
      onValueChange={onValueChangeHandler}
      value={formattedDate}
      helperText={!invalidFormat ? '' : 'Invalid format use dd-mm-yy'}
      error={hasError}
      disabled={disabled}
    />
  )
}
