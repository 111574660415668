import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GuidedTour,
  IUserInfoReadResponse,
  IUserSettingsCargoList,
} from '~api/user/read'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

interface Settings {
  vessels?: {
    favourites: string[]
  }
  positionList?: object | null
  guidedTours?: GuidedTour[]
  cargoList?: IUserSettingsCargoList | null
}

export interface PatchMePayload {
  groups?: string[]
  ToSAcceptedDate?: string
  settings?: Settings
}

export function usePatchMeVT() {
  const fetchRequest = useFetch<IUserInfoReadResponse>()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['/me'],
    mutationFn: async (payload: PatchMePayload) => {
      const response = await fetchRequest(
        `${apiBase()}/me`,
        undefined,
        payload,
        'PATCH',
      )
      return response
    },
    // Optimistic update
    onMutate: async ({ payload }: any) => {
      // Cancel any outgoing refetches so they don't overwrite optimistic update
      await queryClient.cancelQueries({ queryKey: ['/me'] })

      const previousData = queryClient.getQueryData<IUserInfoReadResponse>([
        '/me',
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(['/me'], { ...previousData, ...payload })

      return { previousData }
    },
    onError: (err, payload, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(['/me'], context.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['/me'] })
    },
  })
}
