import { createTheme } from '@mui/material/styles'
import { COLORS } from './colors'
import { MuiAccordion } from './components/accordion'
import { MuiTableRow } from './components/table-row'
import { breakpoints, palette, spacing, typography } from './global'

const themeOptions = createTheme({
  breakpoints,
  palette,
  spacing,
  // @ts-ignore
  typography,
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            backgroundColor: COLORS.secondary.darkBlue,
          },
        },
      },
    },
    MuiAccordion,
    MuiTableRow,
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedPrimary': {
            '&:hover': {
              backgroundColor: COLORS.secondary.barBlue,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.secondary.barBlue,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: COLORS.primary.white,
          color: COLORS.secondary.darkBlue,
          fontSize: 14,
          cursor: 'pointer',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: 10,
        },
      },
    },
  },
})

export default themeOptions
