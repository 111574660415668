import { Box, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'
import { FieldsGroupProps } from '../shared-fields'
import { DateInputField } from './date-input'

export function CpDate({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <DateInputField
        label="C/P Date"
        onChangeHandler={(value) => updateFormInput({ cpDate: value })}
        dateTime={formInput.cpDate}
        hasError={missingFields?.includes('cpDate')}
        disabled={disabledFields?.includes('cpDate')}
      />
    </Box>
  )
}
