import {
  EditIcon,
  LockIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { NonEditableCommentRenderer } from '~components/non-editable-comment-renderer'
import { Tooltip } from '~components/tooltip'
import {
  PatchCargoPayload,
  usePatchCargo,
} from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { COLORS } from '~theme/colors'
import { CargoListPageSearchParams } from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { GetCargoItem } from '../../hooks/use-get-cargo'
import { Type } from '../cargo-modal/add-cargo-modal'
import { FailCargoConfirmation } from '../cargo-modal/update-cargo-modal'
import { showAnimatedAlert } from '../utils/show-animated-alert'
import { ArrayPreviewWithTooltip } from './sub-components/array-preview-with-tooltip'
import { QuantityAndPeriodRenderer } from './sub-components/quantity-and-period-renderer'
import { StatusCell } from './sub-components/status-cell'
import { ValueWithTooltip } from './sub-components/value-with-tooltip'

export function CargoTableBody({ data }: { data: GetCargoItem[] }) {
  const { setStatus } = useAnimatedAlert()
  const [searchParams, setSearchParams] = useSearchParams()
  const [failCargoDialogId, setFailCargoDialogId] = useState<string>()

  const {
    mutate: patchCargo,
    status: patchStatus,
    data: patchedData,
  } = usePatchCargo()

  useEffect(() => {
    showAnimatedAlert({
      setStatus,
      status: patchStatus,
      action: 'patch',
      data: patchedData,
    })
  }, [patchStatus])

  function patchCargoStatus(payload: PatchCargoPayload) {
    if (payload.status === CargoStatus.Failed && !failCargoDialogId) {
      setFailCargoDialogId(payload._id)
      return
    }
    setFailCargoDialogId(undefined)
    patchCargo(payload)
  }

  function onClickHandler(id: string) {
    searchParams.set(CargoListPageSearchParams.editCargo, id)
    setSearchParams(searchParams)
  }

  return (
    <TableBody>
      {failCargoDialogId && (
        <FailCargoConfirmation
          isVisible={!!failCargoDialogId}
          onConfirm={() => {
            searchParams.set(
              CargoListPageSearchParams.recreateCargo,
              failCargoDialogId,
            )
            setSearchParams(searchParams)
            patchCargoStatus({
              _id: failCargoDialogId,
              status: CargoStatus.Failed,
            })
          }}
          onCancel={() => {
            patchCargoStatus({
              _id: failCargoDialogId,
              status: CargoStatus.Failed,
            })
          }}
          onClose={() => setFailCargoDialogId(undefined)}
        />
      )}

      {data?.map((cargo) => {
        const {
          _id,
          broker,
          cargoGrade,
          commercialOperator,
          charterer,
          comments,
          commentsCreatedAt,
          commentsCreatedBy,
          dateQuoted,
          dischargeWorldArea,
          dischargePort,
          isMtFleet,
          isCommercialManagement,
          laycanFrom,
          laycanTo,
          loadWorldArea,
          loadPort,
          periodDetails,
          periodTcOut,
          periodTcOutType,
          pic,
          privateAndConfidential,
          quantity,
          quantityType,
          rateHire,
          rateHireType,
          hireDetails,
          status,
          subsDueTime,
          tradingType,
          vessel,
        } = cargo
        return (
          <TableRow
            key={_id}
            sx={{
              outline: constructBorderStyle({
                tradingType,
                isCommercialManagement,
              }),

              background: isMtFleet
                ? COLORS.secondary.blueTint
                : COLORS.greys.ultraLight,

              td: {
                px: 2,
                py: 0,
                '&:first-of-type': {
                  borderLeft: constructBorderStyle({
                    tradingType,
                    isCommercialManagement,
                  }),
                },
                '&:last-of-type': {
                  borderRight: constructBorderStyle({
                    tradingType,
                    isCommercialManagement,
                  }),
                },
              },
            }}
          >
            <TableCell>
              {privateAndConfidential && (
                <Tooltip
                  title="Private & Confidential"
                  placement="bottom-start"
                >
                  <LockIcon size={16} color={COLORS.feedback.red.error} />
                </Tooltip>
              )}
            </TableCell>
            <TableCell>
              {dateQuoted && (
                <Typography variant="caption">
                  {format(new Date(dateQuoted), 'dd-MM')}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              <ArrayPreviewWithTooltip array={cargoGrade} />
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {`${!laycanFrom ? 'N/A' : format(new Date(laycanFrom), 'dd-MM')} →
            ${!laycanTo ? 'N/A' : format(new Date(laycanTo), 'dd-MM')}`}
              </Typography>
            </TableCell>
            <TableCell>
              <ArrayPreviewWithTooltip
                array={loadPort && !!loadPort.length ? loadPort : loadWorldArea}
              />
            </TableCell>
            <TableCell>
              <ArrayPreviewWithTooltip
                array={
                  dischargePort && !!dischargePort.length
                    ? dischargePort
                    : dischargeWorldArea
                }
              />
            </TableCell>
            <TableCell>
              <Typography variant="caption">{broker}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">{charterer}</Typography>
            </TableCell>
            <TableCell>
              <QuantityAndPeriodRenderer
                {...{
                  quantity,
                  quantityType,
                  periodTcOut,
                  periodTcOutType,
                  periodDetails,
                  tradingType,
                }}
              />
            </TableCell>
            <TableCell>
              <ValueWithTooltip
                value={rateHire}
                tooltipText={
                  <>
                    {rateHireType}
                    <p>{hireDetails}</p>
                  </>
                }
              />
            </TableCell>
            <TableCell>
              <ValueWithTooltip
                value={vessel}
                tooltipText={
                  !commercialOperator
                    ? undefined
                    : `Commercial operator: ${commercialOperator}`
                }
              />
            </TableCell>
            <TableCell>
              <Tooltip title={pic} placement="bottom-start">
                <Typography
                  variant="caption"
                  component="p"
                  sx={{
                    width: '50px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: COLORS.secondary.darkBlue,
                  }}
                >
                  {pic}
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell>
              {comments && (
                <Tooltip
                  placement="bottom-start"
                  title={
                    <NonEditableCommentRenderer
                      comment={comments}
                      userName={commentsCreatedBy}
                      createdAt={commentsCreatedAt}
                    />
                  }
                >
                  <Typography
                    variant="caption"
                    component="p"
                    sx={{
                      width: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {comments}
                  </Typography>
                </Tooltip>
              )}
            </TableCell>
            <StatusCell
              status={status}
              subsDueTime={subsDueTime}
              onChange={(status) => patchCargoStatus({ _id: _id, status })}
            />
            <TableCell sx={{ p: 0 }}>
              <TextButton
                icon={<EditIcon />}
                onClick={() => onClickHandler(_id)}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

function constructBorderStyle({
  tradingType,
  isCommercialManagement,
}: {
  tradingType?: Type
  isCommercialManagement?: boolean
}) {
  return tradingType === 'T/C'
    ? `1px dashed ${COLORS.greys.mid}`
    : isCommercialManagement
      ? `1px solid ${COLORS.greys.mid}`
      : 'none'
}
