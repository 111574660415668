import { useState } from 'react'
import { useGetUsersVt } from '~hooks/queries/users/use-get-users-vt'
import { minCharsForAutocomplete } from '../../../constants'
import { FieldsGroupProps } from '../../shared-fields'
import { SingleItemAutocomplete } from './base-components/single-item-autocomplete'

interface PicAutocompleteProps extends FieldsGroupProps {
  label?: string
}

export function PicAutocomplete({
  updateFormInput,
  formInput,
  missingFields,
  disabledFields,
  label,
}: PicAutocompleteProps) {
  const [inputValue, setInputValue] = useState('')

  const { data } = useGetUsersVt({
    onlyInternal: true,
    name: inputValue,
    disabled: inputValue.length < minCharsForAutocomplete,
  })

  const options = data?.map((user) => user.name) || []

  return (
    <SingleItemAutocomplete
      input={formInput.pic}
      options={options}
      onChangeHandler={(e, newValue) =>
        updateFormInput({ pic: newValue || undefined })
      }
      label={label || 'Person in charge (PIC) *'}
      setInputValue={setInputValue}
      hasError={missingFields?.includes('pic')}
      freeSolo={false}
      disabled={disabledFields?.includes('pic')}
    />
  )
}
