import {
  BurgerMenuIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { NavLink, useSearchParams } from 'react-router-dom'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { PageRoute } from '~routing/constants/page-route'
import { COLORS } from '~theme/colors'
import {
  cargoListEnabled,
  getFeatureToggle,
  positionListV2Enabled,
} from '~utils/feature-toggles'
import SaveLists from './save-lists'

const PageHeader = ({
  setVesselIdsToExport,
  setShowMyLists,
  myListsButtonRef,
}: {
  setVesselIdsToExport?: React.Dispatch<React.SetStateAction<string[]>>
  setShowMyLists?: React.Dispatch<React.SetStateAction<boolean>>
  myListsButtonRef?: React.RefObject<HTMLButtonElement>
}) => {
  const hasCargoListScope = useHasScope(ExactScopeName.cargoList)
  const hasPositionListV2Scope = useHasScope(ExactScopeName.positionListV2)
  const [searchParams] = useSearchParams()
  const cargoGroup = searchParams.getAll('CargoGrade')
  const pool = searchParams.getAll('Pool')

  return (
    <Box
      sx={{
        backgroundColor: COLORS.greys.ultraLight,
        height: '70px',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: { xs: 3, xxl: 6 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 3, xl: 5 },
            overflowX: 'auto',
            width: { xs: '270px', xl: 'auto' },
          }}
        >
          <Box
            component={NavItem}
            link={`${PageRoute.POSITION_LIST}?${cargoGroup ? `cargoGroup=${cargoGroup}` : ''}&${pool ? `pool=${pool}` : ''}`}
            label="Position List"
            sx={{ display: { xs: 'none', xl: 'block' } }}
          />
          {(getFeatureToggle(positionListV2Enabled) ||
            hasPositionListV2Scope) && (
            <NavItem
              link={`${PageRoute.POSITION_LIST_V2}?columns=Open+Date&columns=Open+Port&columns=L3C&columns=Comments&columns=Next+Dry+Dock&columns=Last+SIRE&columns=DWT&columns=IMO&columns=CBM&columns=ICE&columns=IGS&columns=Year+Built&columns=Operator&columns=Scrubber&columns=Flag&ShowBallastDuplicates=Show+Ballast+Duplicates&sort=Open+Date&order=desc&columns=BWTS&columns=Level+3`}
              label="Position List v2 (BETA)"
            />
          )}
          {(getFeatureToggle(cargoListEnabled) || hasCargoListScope) && (
            <NavItem link={PageRoute.CARGO_LIST} label="Cargo List" />
          )}
          <NavItem
            link={`${PageRoute.EXPOSURE}?${cargoGroup ? `cargoGroup=${cargoGroup.toString()}` : ''}&${pool ? `pool=${pool.toString()}` : ''}`}
            label="Exposure"
          />
          <NavItem link={PageRoute.MAP} label="Map" />
        </Box>
        {setVesselIdsToExport && (
          <Box
            component={SaveLists}
            sx={{ ml: { xs: 4, xl: 8 } }}
            key="save-lists-btn"
            setVesselIdsToExport={setVesselIdsToExport}
          />
        )}
        {setShowMyLists && (
          <TextButton
            key="My Lists"
            ref={myListsButtonRef}
            onClick={() => {
              setShowMyLists?.((prev) => !prev)
            }}
            icon={<BurgerMenuIcon />}
            data-cy="my-lists-button"
          >
            My Lists
          </TextButton>
        )}
      </Box>
    </Box>
  )
}

export default PageHeader

function NavItem({ link, label }: { link: string; label: string }) {
  return (
    <NavLink
      to={link}
      style={({ isActive }) => ({
        textDecoration: 'none',
        pointerEvents: isActive ? 'none' : 'auto',
      })}
    >
      {({ isActive }) => (
        <>
          <Typography
            sx={{
              whiteSpace: { xs: 'nowrap', xl: 'normal' },
              backgroundColor: 'none',
              color: !isActive
                ? COLORS.secondary.darkBlue
                : COLORS.secondary.hoverBlue,
              fontFamily: 'MaerskTextRegular',
              fontSize: '0.875rem',
              '&:hover': {
                color: COLORS.secondary.hoverBlue,
              },
            }}
          >
            {label}
          </Typography>
          <Box
            sx={{
              height: '2px',
              width: '100%',
              background: !isActive
                ? 'transparent'
                : COLORS.secondary.hoverBlue,
              transition: 'width 0.3s',
              bottom: '-2px',
              margin: 'auto',
            }}
          />
        </>
      )}
    </NavLink>
  )
}
