import { AlertVariant } from '~components/molecules/animated-alert/animated-alert-provider'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { apiStatusMessages } from '../../content/toast-content'
import { GetCargoItem } from '../../hooks/use-get-cargo'

export function showAnimatedAlert({
  setStatus,
  status,
  action,
  data,
}: {
  setStatus: (text: string, duration?: number, variant?: AlertVariant) => void
  status: string
  action: 'patch' | 'delete'
  data?: GetCargoItem
}) {
  if (status !== 'success' && status !== 'error') return

  const latestUpdateHasVessel = data?.history?.[0]?.vessel
  const latestUpdateHasAllowlistedStatus =
    data?.history?.[0].status === CargoStatus.Fixed ||
    data?.history?.[0].status === CargoStatus.OnSubs

  const statusText =
    (latestUpdateHasVessel || latestUpdateHasAllowlistedStatus) &&
    data?.vessel &&
    data?.isMtFleet
      ? apiStatusMessages.success.extendedPatch({
          action: data?.status === CargoStatus.Fixed ? 'fixed' : 'put on subs',
          vesselName: data.vessel,
        })
      : apiStatusMessages[status][action]

  setStatus(statusText, undefined, status)
}
