import { Checkbox, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { useGetCommercialOperator } from '~hooks/queries/cargo/use-get-commercial-operator'
import { useGetQ88List } from '~hooks/queries/q88/use-get-q88-list'
import { minCharsForAutocomplete, toBeNamedText } from '../../../constants'
import { FieldsGroupProps } from '../../shared-fields'
import { vesselNameBlocklist } from '../commercial-operator'
import { SingleItemAutocomplete } from './base-components/single-item-autocomplete'

export function VesselAutocomplete({
  updateFormInput,
  formInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const [inputValue, setInputValue] = useState('')
  const toBeNamedChecked = formInput.vessel === toBeNamedText

  const { data: commercialOperator } = useGetCommercialOperator({
    vesselName: formInput.vessel,
    disabled:
      !formInput.vessel ||
      (!!formInput.vessel && vesselNameBlocklist.includes(formInput.vessel)),
  })

  const { data: q88List } = useGetQ88List({
    vesselNameAndImo: inputValue,
    disabled:
      toBeNamedChecked ||
      inputValue === toBeNamedText ||
      inputValue.length < minCharsForAutocomplete,
  })

  useEffect(() => {
    if (commercialOperator?.length === 1) {
      updateFormInput({ commercialOperator: commercialOperator[0] })
    }
  }, [commercialOperator])

  const vesselNames = toBeNamedChecked || !q88List ? [] : (q88List as string[])

  function onChangeHandler(e: any, newValue: string | null) {
    updateFormInput({ vessel: newValue || undefined })
  }

  return (
    <>
      <SingleItemAutocomplete
        input={formInput.vessel}
        setInputValue={setInputValue}
        label="Vessel *"
        onChangeHandler={onChangeHandler}
        options={vesselNames}
        hasError={missingFields?.includes('vessel')}
        disabled={
          formInput.vessel === toBeNamedText ||
          disabledFields?.includes('vessel')
        }
      />
      <FormControlLabel
        label="To be named (TBN)"
        control={
          <Checkbox
            checked={formInput.vessel === toBeNamedText}
            disabled={disabledFields?.includes('vessel')}
            onChange={(e, isChecked) =>
              updateFormInput({
                vessel: isChecked ? toBeNamedText : undefined,
              })
            }
          />
        }
      />
    </>
  )
}
