import { PlusIcon, TextButton } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CargoListPageSearchParams } from '../constants'

export function AddCargoButton() {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const hasCreateCargoParam = searchParams.has(
      CargoListPageSearchParams.createCargo,
    )

    function handleKeyDown(event: KeyboardEvent) {
      if (
        hasCreateCargoParam ||
        (event?.target as HTMLElement)?.tagName?.toLowerCase() !== 'body'
      )
        return

      if (event?.key?.toLowerCase() === 'a') {
        addCargo()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [searchParams])

  function addCargo() {
    searchParams.set(CargoListPageSearchParams.createCargo, 'true')
    setSearchParams(searchParams)
  }

  return (
    <>
      <Box
        component={TextButton}
        onClick={addCargo}
        icon={<PlusIcon />}
        sx={{ p: 0 }}
      >
        Add Cargo
      </Box>
      <Typography
        variant="caption"
        component="p"
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        <strong>Pro tip:</strong> press A to add cargo
      </Typography>
    </>
  )
}
